"use strict"

class DisabledPages {
    static get inst() {
        if(!this._inst) {
            this._inst = new DisabledPages()
        }
        return this._inst
    }
    constructor() {
        /**
         * @type {?string[]}
         */
        this.pageIDs = null
        if(sessionStorage.inaccessiblePages) {
            this.pageIDs = sessionStorage.inaccessiblePages.split(
                /\n/
            )
        } else {
            this.resetCache()
        }
    }
    /**
     * @param {string} url
     * @returns {?boolean}
     */
    matches(url) {
        return this.pageIDs && this.pageIDs.includes($.sha256(url))
    }
    resetCache() {
        $.ajax(`/a/inaccessiblePages`).done(
            /**
             * @param {string[]} result
             */
            result => {
                this.pageIDs = result
                sessionStorage.inaccessiblePages = this.pageIDs.join("\n")
            }
        )
    }
}